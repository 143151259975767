import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetScheduleDetails } from 'services/ScheduleService'

export const fetchAppointmentDetails = createAsyncThunk(
    'appointment/fetchAppointmentDetails',
    async ({ userID, date }, thunkAPI) => {
        try {
            const { data } = await apiGetScheduleDetails(date, userID)
            console.log(data)
            return data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const appointment = createSlice({
    name: 'appointment',
    initialState: {
        loading: false,
        selectedAppointment: null,
        selectedAppointmentDate: null,
        error: null,
    },
    reducers: {
        setSelectedAppointmentDate: (state, action) => {
            state.selectedAppointmentDate = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppointmentDetails.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchAppointmentDetails.fulfilled, (state, action) => {
                state.loading = false
                state.selectedAppointment = action.payload
                state.error = null
            })
            .addCase(fetchAppointmentDetails.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export const { setSelectedAppointmentDate } = appointment.actions

export default appointment.reducer
