import { AVAILABILITY_TABLE } from 'constants/db.contants'
import { supabase } from 'utils/supabase'

export const apiGetMediationSchedule = async (
    mediator_id,
    startOfMonth,
    endOfMonth
) => {
    const { data, error } = await supabase
        .from(AVAILABILITY_TABLE)
        .select(`*,cases (*)`)
        .eq('mediator_id', mediator_id)
        .gte('date', startOfMonth)
        .lte('date', endOfMonth)
        .order('date', { ascending: true })

    return { data, error }
}
export async function apiUpdateMediationSchedule({
    date,
    slot,
    status,
    mediator_id,
    slot_time,
}) {
    return await supabase
        .from(AVAILABILITY_TABLE)
        .upsert([{ date, slot, status, mediator_id, slot_time }], {
            onConflict: ['date', 'slot', 'mediator_id'],
        })
}

export async function apiGetScheduleDetails(date, mediator_id) {
    return await supabase
        .from(AVAILABILITY_TABLE)
        .select('*')
        .eq('mediator_id', mediator_id)
        .eq('date', date)
}

export async function updateMediatorSchedule(
    date,
    mediatorId,
    status,
    slot,
    scheduled_case_id
) {
    return await supabase.from(AVAILABILITY_TABLE).upsert(
        [
            {
                date,
                mediator_id: mediatorId,
                status,
                slot,
                scheduled_case_id,
            },
        ],
        {
            onConflict: ['date', 'mediator_id', 'slot'],
        }
    )
}

export const updateAvailityStatus = async (ids, status, caseid) => {
    const { data, error } = await supabase
        .from(AVAILABILITY_TABLE)
        .update({ status, case_id: caseid })
        .in('id', ids)
        .select()
    if (error) throw error
    return data
}
