const appConfig = {
    // apiPrefix: '/api',
    // apiPrefix: 'http://localhost:5000',
    apiPrefix: process.env.REACT_APP_BACKEND_API,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
