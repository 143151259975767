import { supabase } from 'utils/supabase'
import ApiService from './ApiService'

export async function apiSignIn(payload) {
    return await supabase.auth.signInWithPassword(payload)
}

export async function apiGetUserSession() {
    return await supabase.auth.getSession()
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut() {
    return await supabase.auth.signOut()
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
