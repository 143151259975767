import React from 'react'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const getImageNameFromUrl = (url) => {
    if (url) {
        const parts = url?.split('/')
        return parts[parts?.length - 1]
    }
    return ''
}

const FileItem = (props) => {
    const { file, children } = props
    const { type, name, size, url } = file

    console.log(file)

    const getFileExtension = (fileName) => {
        return fileName.split('.').pop().toLowerCase()
    }

    const renderThumbnail = () => {
        let fileName = name
        let fileExtension = ''

        if (url) {
            fileName = getImageNameFromUrl(url)
            fileExtension = getFileExtension(fileName)
        } else if (type) {
            fileExtension = type.split('/')[1]
        }

        const isImageFile =
            type?.split('/')[0] === 'image' ||
            ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)

        if (isImageFile) {
            return (
                <img
                    className="upload-file-image"
                    src={url || URL.createObjectURL(file)}
                    alt={`file preview ${fileName}`}
                />
            )
        }

        if (fileExtension === 'zip' || type === 'application/zip') {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (fileExtension === 'pdf' || type === 'application/pdf') {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile />
            </FileIcon>
        )
    }

    return (
        // <div className="upload-file w-full">
        <div className=" upload-file grid grid-cols-12 gap-2">
            <div className="col-span-2 md:col-span-1 upload-file-thumbnail">
                {renderThumbnail()}
            </div>
            <div className="col-span-8 md:col-span-9  upload-file-info flex flex-col">
                <h6 className="upload-file-name w-full  truncate ">
                    {name || getImageNameFromUrl(url)}
                </h6>
                {size && (
                    <span className="upload-file-size">
                        {getKB(size)} kb
                    </span>
                )}
            </div>
            <div className="col-span-2 flex items-center justify-end">{children}</div>

        </div>
        // </div>
    )
}

export default FileItem
