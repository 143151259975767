import { MEDIATORS_TABLE } from 'constants/db.contants'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { supabase } from 'utils/supabase'
dayjs.extend(utc)
dayjs.extend(timezone)
export const getMediatorInformation = async (id) => {
    const { data, error } = await supabase
        .from(MEDIATORS_TABLE)
        .select('*')
        .eq('mediator_id', id)
        .single()

    if (error) throw error
    return data
}

export const UpdateMediatorAccount = async (mediatorData, mediatorId) => {
    const { error: mediatorError } = await supabase
        .from(MEDIATORS_TABLE)
        .update(mediatorData)
        .eq('user_id', mediatorId)

    if (mediatorError) {
        console.error('Error updating mediator:', mediatorError)
        return false
    }

    return true
}
const createDateFromTimeString = (timeString) => {
    if (!timeString) return null
    const [hours, minutes, seconds] = timeString.split(':').map(Number)
    const now = new Date()
    now.setHours(hours, minutes, seconds, 0)
    return now
}
export const getAccountData = async (mediatorId) => {
    const { data, error } = await supabase
        .from(MEDIATORS_TABLE)
        .select('*')
        .eq('user_id', mediatorId)

    if (error) {
        console.error('Error fetching account data:', error)
        return null
    }

    const accountData = data[0]
    if (accountData) {
        return {
            profile: {
                name: `${accountData.first_name} ${accountData.last_name}`,
                email: accountData.email,
            },
            mediationRates: {
                halfday: accountData.half_day_rate,
                fullday: accountData.full_day_rate,
            },
            invoiceMemo: {
                policy: accountData.cancellation_policy,
                additional: accountData.additional_information,
                invoice_footer: accountData?.invoice_footer,
            },
            reminderDays: {
                payment_reminder_days: accountData?.payment_reminder_days,
                brief_reminder_days: accountData?.brief_reminder_days,
            },
            mediationTime: {
                morning: createDateFromTimeString(accountData?.morning_time),
                afternoon: createDateFromTimeString(
                    accountData?.afternoon_time
                ),
                morning_time: accountData?.morning_time,
                afternoon_time: accountData?.afternoon_time,
                timezone: accountData?.timezone,
            },
        }
    }

    return {}
}
